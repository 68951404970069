:root{ 
    --data-color-red: #eb0029; 
    --data-color-white: #ffffff; 
    --data-color-black: rgba(0, 0, 0, 0.85); 
    --data-color-grey:  #818385; 
}



.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}
// text align
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}



// border-radius
$scales: 1, 3, 5, 7, 10, 12, 15, 25, 50, 100;
@each $size in $scales {
  .b-r-#{$size} {
    border-radius: $size + px !important;
  }
}

$scales: 300, 400, 500, 600, 700, 800, 900;
@each $size in $scales {
  .fw-#{$size} {
    font-weight: $size !important;
  }
}

$scales: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 80, 60, 70, 90, 100, 120, 150,180;
@each $size in $scales {
  // margin
  .m-#{$size} {
    margin: $size + px !important;
  }
  .m-t-#{$size} {
    margin-top: $size + px !important;
  }
  .m-b-#{$size} {
    margin-bottom: $size + px !important;
  }
  .m-l-#{$size} {
    margin-left: $size + px !important;
  }
  .m-r-#{$size} {
    margin-right: $size + px !important;
  }

  // padding
  .p-#{$size} {
    padding: $size + px !important;
  }
  .p-t-#{$size} {
    padding-top: $size + px !important;
  }
  .p-b-#{$size} {
    padding-bottom: $size + px !important;
  }
  .p-l-#{$size} {
    padding-left: $size + px !important;
  }
  .p-r-#{$size} {
    padding-right: $size + px !important;
  }

  .h-#{$size}{
    height:$size + px !important;
  }
}

// colors

.text-white{
  color : var(--data-color-white)!important;
}

.fs-24 {
  font-size: 24px!important;
}

// editor css
.ql-editor {
  background-color: #fff;
}

// all forms label
.ant-form-item-label {
  padding-bottom: 0 !important;
}

.foruser {
  .ant-upload.ant-upload-drag {
    border-radius: 50% !important;
    width: 206px !important;
    background: #ffffff !important;
    border: 1px dashed #ffffff !important;
    height: 214px !important;
    margin: auto;
  }
  .ant-upload-list {
    width: 206px !important;
    margin: auto !important;
  }
  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: auto;
    .avatar-edit {
      position: absolute;
      right: 12px;
      z-index: 1;
      top: 10px;
      input {
        display: none;
        + label {
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-bottom: 0;
          border-radius: 100%;
          background: #ffffff;
          border: 1px solid transparent;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          font-weight: normal;
          transition: all 0.2s ease-in-out;
          &:hover {
            background: #f1f1f1;
            border-color: #d6d6d6;
          }
          &:after {
            content: "\f040";
            font-family: "FontAwesome";
            color: #757575;
            position: absolute;
            top: 10px;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
          }
        }
      }
    }
    .avatar-preview {
      width: 192px;
      height: 192px;
      margin: auto;
      position: relative;
      border-radius: 100%;
      border: 6px solid #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

// ant-dropdown-menu
.ant-dropdown-menu{
  padding: 4px 0 0!important;
}



