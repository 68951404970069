#fotter {
  overflow: hidden;
  position: relative;

  bottom: 0;

  .fotter {
    background: #162045;

    .sosyalmedya-hesaplar {
      padding: 15px 0;
      display: flex;
      align-items: center;

      .sosyal-media-icon {
        .icon-list {
          list-style-type: none;

          a {
            text-decoration: none;
          }

          li {
            display: inline;
            cursor: pointer;
            margin-right: 5px;
            width: 10px;
            height: 10px;

            .icon-list-background {
              background: white !important;
              height: 50px;
              color: #162045 !important;
              margin-right: 6px;
              border-radius: 4px;
              padding: 2px 6px;
              transition: all 0.5s ease 0s;
            }

            .facebook:hover {
              .icon-list-background {
                background: #3b5998;
                color: #fff;
              }

              .icon-span {
                color: #3b5998;
              }
            }

            .youtube:hover {
              .icon-list-background {
                background: red;
                color: #fff;
              }

              .icon-span {
                color: red;
              }
            }

            .instegram:hover {
              .icon-list-background {
                background: rgb(170, 51, 176);
                color: #fff;
              }

              .icon-span {
                color: rgb(170, 51, 176);
              }
            }

            .twitter:hover {
              .icon-list-background {
                background: #1da1f2ed;
                color: #fff;
              }

              .icon-span {
                color: #1da1f2ed;
              }
            }

            .icon-span {
              background: #162045;
              color: white;
              font-size: 13px;
              margin-right: 20px;
            }
          }

          li:nth-of-type(1) {
            .icon-list-background {
              // svg {
              //   font-size: 27px;
              //   padding: 0px 0px 3px;
              // }
            }
          }

          // li:nth-of-type(3) {
          //   .icon-list-background {
          //     background: none;
          //     svg {
          //       font-size: 26px;
          //       color: white;
          //       padding: 0 0 3px;
          //     }
          //   }
          // }
          // li:nth-of-type(4) {
          //   .icon-list-background {
          //     background: none;
          //     svg {
          //       font-size: 36px;
          //       color: rgb(255 255 255);
          //       padding: 0 4px;
          //       margin-right: -4px;
          //     }
          //   }
          // }
        }
      }
    }

    .baskan {
      background: #1f2c5a;
      width: 100%;
      padding: 60px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .baskan-bilgileri {
        width: 40%;
        height: 10px;
      }

      .navbar {
        .nav-item {
          padding: 0 10px;
          font-size: 15.5px;
          text-transform: uppercase;
          span {
            color: #fff !important;
          }
          opacity: 0.6;
          -webkit-transition: 0.3s;
          transition: 0.3s;
        }

        span {
          transition: all 0.6s ease 0s;
          font-weight: 700;
          color: #fff;
        }

        span:hover {
          color: rgb(30 181 84);
        }
      }
    }
  }
}

@media (min-width: 1020px) and (max-width: 1200px) {
  #fotter {
    .fotter {
      .baskan {
        .navbar {
          .nav-item {
            padding: 0 11px;
            font-size: 13.5px;
            span {
              color: #fff !important;
            }
          }

          li {
            padding: 0 8px;

            a {
              padding: 0;
            }
          }

          .nav-link {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 1020px) {
  #fotter {
    .fotter {
      display: flex;
      align-items: center;
      flex-direction: column;

      .sosyalmedya-hesaplar {
        height: 80px;
        justify-content: space-between;

        .sosyal-media-icon {
          .icon-list {
            li {
              margin-right: 5px;

              .icon-list-background {
                margin-right: 3px;

                .icon {
                  width: 15x;
                  padding: 0px 5px 1px 5px;
                  font-size: 27px;
                }
              }

              .icon-span {
                background: #162045;
                color: white;
                font-size: 13px;
                margin-right: 5px;
              }
            }

            li:nth-of-type(4) {
              .icon-list-background {
                svg {
                  margin: 0 0 0 -5px;
                }
              }
            }
          }
        }
      }

      .baskan {
        padding: 40px 0;

        .baskan-bilgileri {
          width: 150px;
          height: 30px;
        }

        .navbar {
          .nav-item {
            padding: 0 8px;
            font-size: 12.5px;
            span {
              color: #fff !important;
            }
          }

          li {
            padding: 0 8px;

            a {
              padding: 0;
            }
          }

          .nav-link {
            font-size: 13.2px;
          }
        }
      }
    }
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  #fotter {
    .fotter {
      height: 250px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .sosyalmedya-hesaplar {
        justify-content: space-between;

        .sosyal-media-icon {
          .icon-list {
            li {
              width: 5px;
              height: 5px;

              .icon-list-background {
                margin-right: 0px;

                .icon {
                  padding: 0px 2px 1px 2px;
                  font-size: 22px;
                }
              }

              .icon-span {
                display: none;
              }
            }

            li:nth-of-type(1) {
              .icon-list-background {
                svg {
                  font-size: 22px;
                  padding: 0px 0px 3px 0px;
                }
              }
            }

            li:nth-of-type(3) {
              .icon-list-background {
                background: none;

                svg {
                  font-size: 24px;
                  // color: white;
                  padding: 0 0 3px;
                }
              }
            }

            li:nth-of-type(4) {
              .icon-list-background {
                svg {
                  font-size: 35px;
                  margin: 0 0 0 -12px;
                }
              }
            }
          }
        }
      }

      .baskan {
        padding: 40px 0;

        .baskan-bilgileri {
          width: 180px;
          height: 50px;
        }

        .navbar {
          .nav-item {
            padding: 0 8px;
            font-size: 12.5px;
            span {
              color: #fff !important;
            }
          }

          li {
            padding: 0 3.42px;

            a {
              padding: 0;
            }
          }

          .nav-link {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  #fotter {
    .fotter {
      height: 250px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .sosyalmedya-hesaplar {
        justify-content: space-between;

        .sosyal-media-icon {
          .icon-list {
            li {
              width: 5px;
              height: 5px;

              .icon-list-background {
                margin-right: 0px;

                .icon {
                  padding: 0px 2px 1px 2px;
                  font-size: 22px;
                }
              }

              .icon-span {
                display: none;
              }
            }

            li:nth-of-type(1) {
              .icon-list-background {
                svg {
                  font-size: 22px;
                  padding: 0px 0px 3px 0px;
                }
              }
            }

            li:nth-of-type(3) {
              .icon-list-background {
                background: none;

                svg {
                  font-size: 24px;
                  // color: white;
                  padding: 0 0 3px;
                }
              }
            }

            li:nth-of-type(4) {
              .icon-list-background {
                svg {
                  font-size: 35px;
                  margin: 0 0 0 -12px;
                }
              }
            }
          }
        }
      }

      .baskan {
        padding: 40px 0;

        .baskan-bilgileri {
          width: 180px;
          height: 50px;
        }

        .navbar {
          .nav-item {
            font-size: 9px;
            padding: 0 6px;
            span {
              color: #fff !important;
            }
          }

          li {
            padding: 0 3.42px;

            a {
              padding: 0;
            }
          }

          .nav-link {
            font-size: 11px;
          }
        }
      }
    }
  }
}
