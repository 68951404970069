#haberler {
  margin-top: 50px;
  overflow: hidden;
  position: relative;

  .haberler {
    padding: 30px 30px 0;

    h1 {
      color: #707070;
      font-weight: bold;
      display: flex;
      font-size: 23px;
      justify-content: center;
      margin-bottom: 20px;
    }

    .haberler-slider {
      padding: 0;
      margin-top: 60px;

      .slick-slide {
        padding: 0 5px;
      }

      .slick-prev:before,
      .slick-next:before {
        color: #c32e2e;
        font-size: 36px;
      }

      .slick-list {
        z-index: 100;
      }

      .slick-prev {
        line-height: none;
        transform: translate(-50%, -50%);
      }

      .slick-next {
        transform: translate(-50%, -50%);
        line-height: none;
      }

      .slick-dots {
        top: -60px;

        li {
          width: 75px;
          height: 2px;
          margin: 0;
        }
      }

      .slick-dots li.slick-active button:before {
        background: #c26161;
        border-radius: 5px;
        opacity: 7;
        color: #ff000000;
        width: 100%;
        height: 3px;
      }

      .slick-dots li button:before {
        background: #b2b2b2;
        color: #ff000000;
        opacity: 7;
        width: 100%;
        height: 3px;
      }

      .card {
        border: none;
        position: relative;
        z-index: 99999;
        height: 450px;
        border: 1px solid #dde4eb;

        .card-header {
          overflow: hidden;
          padding: 0;
          margin: 0;
          border: none;
          width: 100%;
          margin-right: 1px;
          background-color: none;
          position: relative;
          max-height: 250px;

          .img {
            width: 100%;
            height: 250px;
          }

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: #00000024;
            z-index: 1;
            top: 0;
            left: 0;
          }

          &:hover {
            .img {
              -webkit-transition: transform 2s;
              -moz-transition: transform 2s;
              -o-transition: transform 2s;
              transition: transform 2s;
              transform: scale(1.2);
            }
          }
        }

        .card-header:hover + .img {
          -webkit-transition: transform 2s;
          -moz-transition: transform 2s;
          -o-transition: transform 2s;
          transition: transform 2s;
          transform: scale(1.2);
        }

        .card-title {
          color: #000;
          font-size: 16px;
          text-decoration: none;
          font-weight: 700;
          display: inline-block;
          // max-width: 35ch;
          // max-height: 2ex;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .card-body {
          max-height: 150px;
          line-height: 1.4;
          font-size: 17px;
          font-weight: 400;
          color: rgb(36, 35, 35);
          display: inline-block;
          text-decoration: none;
          transition: all 0.3s ease;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .card-text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 0.99rem;
            color: #494949;
            margin: 10px 0;
            font-weight: 500;

            &:hover {
              color: #e7191e;
              transition: 2s;
            }
          }

          .btn {
            position: absolute;
            color: white;
            padding: 18px 12px;
            border-radius: 4px;
            font-size: 15px;
            bottom: 15px;
            cursor: pointer;
            background: #c32e2e;
            border: none;
            transition: all 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              padding: 5px 10px;
              font-size: 13px;
            }
            &:hover {
              color: #c32e2e;
              background: white;
              border: 1px solid #c32e2e;
            }
          }
          .fotter-card {
            .tarih-etiketi {
              position: absolute;
              bottom: 20px;
              right: 8px;
              display: flex;
              align-items: center;
              color: #6e97ad;
              border-radius: 3px;
              text-align: center;
              line-height: 1;
              font-size: 0.8rem;
              .icon {
                margin-right: 5px;
              }
              @media only screen and (max-width: 1024px) {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }

    .tum-btn {
      margin-top: 100px;
      display: flex;
      justify-content: center;

      .btn {
        border: none;

        .tum-haberler-btn {
          height: 40px;
          font-size: 15px;
          color: #b64140;
          padding: 4px 25px;
          font-weight: 700;
          border-radius: 35px;
          border: 1px solid #a44e4e;
          transition: all 0.5s;

          &:focus {
            border-color: #a44e4e;
          }

          &:hover {
            color: white;
            background: #b64140;
          }
        }
      }
    }
  }
}

@media (min-width: 1001px) and (max-width: 1101px) {
  #haberler {
    padding: 0 10px;

    .haberler {
      .haberler-slider {
        .slick-dots {
          li {
            width: 70px;
          }
        }

        .card {
          height: 390px;

          .card-header {
            max-height: 200px;

            .img {
              width: 100%;
              height: 250px;
            }
          }

          .card-body {
            max-height: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 325px) {
  #haberler {
    .tum-btn {
      margin-top: 50px !important;
    }

    .haberler {
      .haberler-slider {
        .slick-dots {
          li {
            width: 18px;
          }
        }

        .card {
          height: 345px;

          .card-header {
            height: 170px;

            .img {
              width: 100%;
              height: 170px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 326px) and (max-width: 480px) {
  #haberler {
    .tum-btn {
      margin-top: 50px !important;
    }

    .haberler {
      .haberler-slider {
        .slick-dots {
          li {
            width: 20px;
          }
        }

        .card {
          height: 380px;

          .card-header {
            max-height: 200px;

            .img {
              width: 100%;
              height: 200px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 1000px) {
  #haberler {
    .tum-btn {
      margin-top: 50px !important;
    }

    .haberler {
      .haberler-slider {
        .slick-dots {
          li {
            width: 50px;
          }
        }

        .card {
          height: 370px;

          .card-header {
            max-height: 200px;

            .img {
              width: 100%;
              height: 200px;
            }
          }
        }
      }
    }
  }
}
