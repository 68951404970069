// @import "./loader.scss";
@import "./variable.scss";
@import "./callout.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Cinzel+Decorative:wght@900&family=Comforter&family=Corinthia:wght@400;700&family=DM+Sans:ital,wght@0,400;0,500;1,400&family=Dancing+Script&family=Fuzzy+Bubbles&family=Gwendolyn&family=Heebo:wght@100;200;300;400;500&family=Inter:wght@100;200;300;400;500;600;700;800&family=Lato:ital,wght@1,300;1,900&family=Lobster&family=M+PLUS+1+Code&family=Meow+Script&family=Nunito+Sans:ital,wght@0,300;1,200;1,300&family=Nunito:wght@300&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,700&family=Oswald:wght@200;300;400;500;600&family=Playfair+Display:ital@1&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Readex+Pro:wght@300;400;500;600;700&family=Roboto+Condensed:wght@700&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@300&family=Source+Code+Pro:ital,wght@1,300&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yellowtail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Cinzel+Decorative:wght@900&family=Comforter&family=Corinthia:wght@400;700&family=DM+Sans:ital,wght@0,400;0,500;1,400&family=Dancing+Script:wght@400;500;600;700&family=Fuzzy+Bubbles&family=Gwendolyn&family=Heebo:wght@100;200;300;400;500&family=Inter:wght@100;200;300;400;500;600;700;800&family=Lato:ital,wght@1,300;1,900&family=Lobster&family=M+PLUS+1+Code&family=Meow+Script&family=Nunito+Sans:ital,wght@0,300;1,200;1,300&family=Nunito:wght@300&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,700&family=Oswald:wght@200;300;400;500;600&family=Playfair+Display:ital@1&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Readex+Pro:wght@300;400;500;600;700&family=Roboto+Condensed:wght@700&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@300&family=Source+Code+Pro:ital,wght@1,300&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yellowtail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;1,200&family=Open+Sans:wght@300&family=Poppins:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;1,200&family=Open+Sans:wght@300&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); // library
// @import '../node_modules/react-modal-video/scss/modal-video.scss';
// // layout
// @import "../layout/web/Header/header.scss";
// @import "../layout/web/Footer/footer.scss";
// @import "../layout/web/Header/slides.scss";
// // diğer components
// @import "../components/web-components/vefatdetay/vefatdetay.scss";
// @import "../components/web-components/vefateden/vefateden.scss";
// @import "../components/web-components/eskibaskanlarimiz/eskibaskanlarimiz.scss";
// @import "../components/web-components/Baskanyardimcidetay/Baskanyardimcidetay.scss";
// @import "../components/web-components/baskanyardimcisi/baskanyardimcisi.scss";
// @import "../components/web-components/projedetay/projedetay.scss";
// @import "../components/web-components/fotografdetay/fotografdetay.scss";
// @import "../components/web-components/haberdetay/haberdetay.scss";
// @import "../components/web-components/tumhaberler/tumhaberler.scss";
// @import "../components/web-components/tumprojeler/tumprojeler.scss";
// @import "../components/web-components/fotografgalerisi/fotografgalerisi.scss";
// @import "../components/web-components/haberler/haberler.scss";
// @import "../components/web-components/haberler/theme.scss";
// @import "../components/web-components/projeler/projeler.scss";
// @import "../components/web-components/goruntuler/goruntuler.scss";
// @import "../components/web-components/etkinlikler/etkinlikler.scss";
// @import "../components/web-components/ihaleler/ihaleler.scss";
// @import "../components/web-components/duyurular/duyurular.scss";
// @import "../components/web-components/etkin/etkin.scss";
// @import "../components/web-components/websitemiz/websitemiz.scss";
// @import "../components/web-components/Viransehir/muhtarlar/muhtarlar.scss";
// @import "../components/web-components/Viransehir/virantarih/virantarih.scss";
// @import "../components/web-components/Viransehir/nufus/nufus.scss";
// @import "../components/web-components/Viransehir/ulasim/ulasim.scss";
// @import "../components/web-components/Viransehir/cografi/cografi.scss";
// @import "../components/web-components/Viransehir/sosyalyapi/sosyalyapi.scss";
// @import "../components/web-components/Viransehir/harita/harita.scss";
// @import "../components/web-components/videogalerisi/videogalerisi.scss";
// @import "../components/web-components/pagination/pagination.scss";
// @import "../components/web-components/baskan/baskan.scss";
// @import "../components/web-components/iletesim/iletesim.scss";
// @import "../components/web-components/encumen/encumen.scss";
// @import "../components/web-components/mudurlukler/mudurluklar.scss";
// @import "../components/web-components/formlar/beyazmasa/beyazmasa.scss";
// @import "../components/web-components/formlar/sikayatform/sikayatform.scss";
// // sidebars
// @import "../components/web-components/sidebars/sidebarContents/sidebarContents.scss";
// @import "../components/web-components/sidebars/sidebarKategoris/sidebarKategoris.scss";

// başkan site
//@import "../layout/baskanLayout/header/header.scss";
//@import "../layout/baskanLayout/header/header.scss";
@import "layout/baskanLayout/navbar/navbar.scss";
@import "layout/baskanLayout/fotter/fotter.scss";
@import "../components/site/header/header.scss";
@import "../components/site/haberler/haberler.scss";
@import "../components/site/hakkimda/hakkimda.scss";
@import "../components/site/galeri/galeri.scss";
@import "../components/site/ulasim/ulasim.scss";

.cPointer {
  color: black;
  cursor: pointer;
}

body {
  // font-family: "Alegreya", sans-serif !important;
  font-family: "Nunito Sans", sans-serif !important;
}

.head_line {
  width: 150px;
  height: 3px;
  margin: 10px auto;
  background: #b2b2b2;
  margin-bottom: 40px;
  position: relative;

  span {
    width: 70px;
    height: 100%;
    background: #c26161;
    display: block;
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 transparent !important;
}

.hrprofil {
  margin: 5px 0px !important;
}

.ant-switch-checked {
  background: #405189;
}

/* banner */
.banner img {
  border-radius: 6px;
}

//for Kategori
.popover-style-content {
  display: flex !important;

  button {
    margin-left: 10px;
    height: 35px;
    padding: 0 7px;
  }
}

.hoverimage img {
  cursor: pointer;
}

.ant-upload-list-item-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
}

@for $i from 1 through 150 {
  .titlech-#{$i} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: #{$i}ch;
  }
}

.ant-picker-panel {
  padding: 15px;
}

#components {
  &>h1 {
    text-align: center;
  }

  .left {
    background-color: #fff;
    height: 100vh;

    .cardCom {
      p {
        padding: 0;
        text-align: center;
      }

      width: 90%;
      margin: 30px auto;
      // background-color: #fff;
      max-height: 500px;
      display: block;
      overflow-y: auto;
    }
  }
}

.pageHeader {
  margin-bottom: 15px;
  background-color: #00bcd400;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  .ant-page-header {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 7px;
    padding: 15px 0 0 0;

    .ant-page-header-heading {
      width: 100%;
    }

    .ant-page-header-heading-title {
      color: #e10614;
      font-weight: 700;
    }

    .ant-breadcrumb {
      max-width: 150ch;

      a {
        transition: color 0.3s;
        font-weight: 500;
        color: #7a93a9;
        font-size: 0.8rem;
      }

      ol li:last-child {
        max-width: 80ch;
        text-overflow: ellipsis;
        overflow: hidden;

        .ant-breadcrumb-link {

          font-weight: 600;
          color: #7a93a9;
          font-size: 0.8rem;
        }
      }
    }

    .ant-page-header-heading-left {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }

  .ant-page-header-heading-left {
    margin: 0;
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
}

@media (min-width: 1201px) {
  .ant-image-preview-img {
    max-height: 65% !important;
    max-width: 65% !important;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .ant-image-preview-img {
    max-height: 70% !important;
    max-width: 70% !important;
  }

  // .ant-page-header-heading-sub-title {
  //   max-width: 80ch;

  //   .ant-breadcrumb {
  //     ol {
  //       li {
  //         .ant-breadcrumb-separator {
  //           margin: 0 4px;
  //         }
  //       }
  //     }

  //     ol li:nth-child(3) {
  //       max-width: 70ch;
  //       text-overflow: ellipsis;
  //       overflow: hidden;
  //     }
  //   }
  // }
}

@media (min-width: 320px) and (max-width: 768px) {
  .ant-image-preview-img {
    max-height: 85% !important;
    max-width: 85% !important;
  }
}

.ant-image-mask {
  border-radius: 15px;
}

.ant-image-preview-mask {
  background-color: rgb(0 0 0 / 80%) !important;
}

.ant-image-mask-info {
  visibility: hidden;
}

.ant-image-preview-operations {
  >li {
    >span:not(.anticon-close) {
      visibility: hidden;
    }
  }
}

.ant-image-mask {
  border-radius: 5px;
}

a {
  text-decoration: none;
}

@media (max-width: 550px) {
  .pageHeader {
    font-size: 10px;

    .ant-page-header {
      .ant-page-header-heading-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .ant-page-header-heading-sub-title {
          max-width: 100ch;

          .ant-breadcrumb {
            ol {
              li {
                .ant-breadcrumb-separator {
                  margin: 0 4px;
                }
              }
            }

            ol li:nth-child(3) {
              max-width: 18ch;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 551px) and (max-width: 992px) {
  .pageHeader {
    .ant-page-header {
      .ant-page-header-heading-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .ant-page-header-heading-sub-title {
          max-width: 70ch;

          .ant-breadcrumb {
            ol li:nth-child(3) {
              max-width: 50ch;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}