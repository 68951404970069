#baskan-hakkinda {
  margin: 35px 0 0;
  background: #dde4eb54;
  padding: 25px 0 50px;
  overflow: hidden;
  position: relative;

  .baskan-hakkinda {
    // .before {
    //   position: absolute;
    //   width: 745px;
    //   height: 625px;
    //   margin-top: -65px;
    //   left: -100px;
    //   z-index: 2;
    //   background-size: 100%;
    //   background-repeat: no-repeat;
    //   background-image: url(/baskan-img/shape-02.png);
    // }
    // .after {
    //   position: absolute;
    //   right: -60px;
    //   margin-top: -111px;
    //   z-index: 2;
    //   width: 800px;
    //   height: 730px;
    //   background-size: 100%;
    //   background-repeat: no-repeat;
    //   background-image: url(/baskan-img/shape-02.png);
    // }

    h1 {
      color: #707070;
      font-weight: bold;
      display: flex;
      font-size: 23px;
      justify-content: center;
    }

    .baskan-hakkinda-satir {
      margin-bottom: 30px;

      .image {
        border-radius: 8px;
      }

      .right-col {
        padding: 0px 25px;
        height: 100%;

        .salih-ekinci-bilgileri {
          .salih-ekinci {
            margin-bottom: 15px;

            p {
              color: #707070;
              font-size: 15px;
              font-weight: bold;
            }

            span {
              width: 55px;
              height: 3px;
              display: block;
              margin-top: -15px;
              background: #c26161;
            }
          }

          .salih-ekinci-btn {
            .accordion {
              .accordion-item {
                z-index: 50;
                background-color: #6f707000;
                border: none;
                margin-bottom: 10px;

                button {
                  height: 35px;
                  color: #7b7b7b;
                  box-shadow: none;
                  padding-left: 10px;
                  z-index: 50;
                  border-radius: 25px;
                  padding: 25px;
                  font-weight: 900;

                  &::after {
                    transform: rotate(-90deg);
                  }
                }

                button:not(.collapsed) {
                  background: #c32e2e;
                  z-index: 50;
                  color: #fff;
                  font-size: 18px;
                  font-weight: 700;

                  &::after {
                    color: #fff3f5;
                    margin-top: 10px;
                    transform: rotate(0deg);
                    background-image: url("https://icon-library.com/images/white-down-arrow-icon/white-down-arrow-icon-9.jpg");
                  }
                }

                .icon {
                  display: flex;
                  justify-content: flex-end;
                }

                .accordion-body {
                  background: transparent;
                  color: #6f7070;
                  padding: 14px 5px 14px 15px;
                  font-size: 15px;

                  span p {
                    text-align: justify;
                    font-size: 0.95rem;
                    font-weight: 600;
                    letter-spacing: 0.05rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .accordion-body h3 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 800px) {
  #baskan-hakkinda {
    padding: 25px 0px 0px !important;
    .baskan-hakkinda {
      .before {
        display: none;
      }

      .after {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #baskan-hakkinda {
    overflow: hidden;
    // .baskan-hakkinda {
    //   .before {
    //     position: absolute;
    //     width: 580px;
    //     height: 625px;
    //     margin-top: -75px;
    //     left: -226px;
    //     z-index: 2;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     background-image: url(/baskan-img/shape-02.png);
    //   }
    //   .after {
    //     position: absolute;
    //     margin-right: -137px;
    //     margin-top: -114px;
    //     width: 620px;
    //     height: 625px;
    //     z-index: 2;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     background-image: url(/baskan-img/shape-02.png);
    //   }
    // }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  #baskan-hakkinda {
    overflow: hidden;
    // .baskan-hakkinda {
    //   .before {
    //     position: absolute;
    //     width: 660px;
    //     height: 625px;
    //     margin-top: -75px;
    //     left: -226px;
    //     z-index: 2;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     background-image: url(/baskan-img/shape-02.png);
    //   }
    //   .after {
    //     position: absolute;
    //     margin-right: -137px;
    //     margin-top: -114px;
    //     width: 720px;
    //     height: 625px;
    //     z-index: 2;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     background-image: url(/baskan-img/shape-02.png);
    //   }
    // }
  }
}

@media (min-width: 1500px) and (max-width: 1440px) {
  #baskan-hakkinda {
    overflow: hidden;
    // .baskan-hakkinda {
    //   .before {
    //     position: absolute;
    //     width: 746px;
    //     height: 625px;
    //     margin-top: -65px;
    //     left: -279px;
    //     z-index: 2;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     background-image: url(/baskan-img/shape-02.png);
    //   }
    //   .after {
    //     position: absolute;
    //     margin-right: -137px;
    //     margin-top: -114px;
    //     width: 772px;
    //     height: 640px;
    //     z-index: 2;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     background-image: url(/baskan-img/shape-02.png);
    //   }
    // }
  }
}

@media (max-width: 768px) {
  .right-col {
    padding: 30px !important;
  }

  .baskan-hakkinda-satir {
    margin-bottom: 0px !important;
  }

  #baskan-navbar {
    height: 100%;
  }
}
