#foto-galeri {
  overflow: hidden;
  background: #fff;
  padding: 25px 0;

  // .ant-image {
  //   height: 100%;
  //   display: block;
  // }

  .galeri {
    h1 {
      color: #707070;
      font-weight: bold;
      display: flex;
      font-size: 25px;
      justify-content: center;
    }

    .head_line {
      span {
        width: 50%;
      }
    }

    .galeri-foto {
      font-weight: 500;

      .col-md-4 {
        padding: 0 6px;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        text-shadow: 0 0 0.25px currentcolor;
        background: #fe0000;
        color: white;
        border-radius: 35px;
        border: 1px solid #fe0000;
      }

      .ant-tabs-ink-bar {
        background: none;
      }

      .ant-tabs-tab-btn {
        margin: 0;
        color: #455f76;
        padding: 6px 6px;
        width: 120px;
        font-size: 0.7rem;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        border: 1px solid #dde4eb;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0px 5px;
      }

      .ant-spin-nested-loading {
        background: white;
      }

      .ant-spin-nested-loading>div>.ant-spin {
        background: white;
        max-height: 1000px;
      }

      .ant-tabs-nav-wrap {
        border: 1px solid #fff;
        width: 100%;
        justify-content: center;
        align-items: center;
      }

      .galeri-satir {
        .card {
          max-height: 280px;
          border: 1px solid #fff;
          z-index: 999;
          overflow: hidden;
          margin-bottom: 20px;
          border-radius: 8px;
          // height: 100%;
        }

        .img-box {
          position: relative;
          z-index: 101;
          // height: 95%;
          // width: 94%;
          margin: auto;

          .img {
            position: relative;
            overflow: hidden;
            cursor: pointer;
            // max-height: 187px;
            // height: 100%;

            // &::before {
            //   position: absolute;
            //   content: "";
            //   width: 100%;
            //   height: 5px;
            //   background: #fa1d1d;
            //   bottom: 0;
            //   left: 0;
            //   z-index: 100;
            // }

            img {
              position: relative;
              z-index: 99;
              // height: 100%;

              &:hover {
                -webkit-transition: transform 2s;
                -moz-transition: transform 2s;
                -o-transition: transform 2s;
                transition: transform 2s;
                transform: scale(1.2);
                background-color: red;
              }
            }
          }

          @media (min-width: 768px) {
            .img {
              position: relative;
              overflow: hidden;
              cursor: pointer;
              // max-height: 250px;
              // height: 100%;
            }
          }

          // .card-body {
          //   background: #f7f7f7;
          //   padding: 18px 25px 40px;
          //   height: 107px;
          //   position: relative;

          //   &::before {
          //     position: absolute;
          //     content: "";
          //     width: 100%;
          //     height: 5px;
          //     background: #fa1d1d;
          //     top: 0;
          //     left: 0;
          //     z-index: 100;
          //   }

          //   .card-title {
          //     h3 {
          //       color: #222;
          //       font-size: 18px;
          //       font-weight: 700;
          //       line-height: 1.3;
          //       overflow: hidden;
          //       overflow: hidden;
          //       display: -webkit-box;
          //       -webkit-line-clamp: 2;
          //       -webkit-box-orient: vertical;
          //     }
          //   }

          //   .card-text {
          //     margin-bottom: 0;

          //     color: #999;
          //     font-size: 12px;
          //     position: absolute;
          //     bottom: 15px;
          //     left: 25px;

          //     .icon {
          //       font-size: 17px;
          //       margin-right: 10px;
          //     }
          //   }
          // }
        }

        .video-liste {
          width: 100%;
          position: relative;

          &::before {
            position: absolute;
            cursor: pointer;
            content: "";
            width: 100%;
            height: 100%;
            z-index: 88851;
            background: #0000;
          }
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        border: none;

        button {
          height: 40px;
          background: #fff;
          font-size: 15px;
          color: #b64140;
          padding: 4px 25px;
          font-weight: 700;
          border-radius: 35px;
          border: 1px solid #a44e4e;
          transition: all 0.5s;

          &:hover {
            color: white;
            background: #b64140;
          }
        }
      }

      iframe {
        border: 0;
        width: 100%;
        height: 300px;
      }
    }
  }
}