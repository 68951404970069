// alerts callout
/* my own interpretation of Bootstrap callout */
.callout {
  padding: 5px !important;
  margin: 5px 15px !important;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 4px;

  li {
    text-align: left;
    font-size: 13px;

    div {
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.callout-xl {
  margin-left: 0px;
}

.callout-lg {
  margin-left: 50px;
}

.callout-md {
  margin-left: 100px;
}

.callout-sm {
  margin-left: 150px;
}

.callout-xs {
  margin-left: 200px;
}

.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout code {
  border-radius: 4px;
}

.callout+.callout {
  margin-top: -5px;
}

.callout-default {
  border-left-color: #777;
  background-color: #f7f7f9;
}

.callout-default .callout-title {
  color: #777;
}

.callout-primary {
  border-left-color: #428bca;
}

.callout-primary .callout-title {
  color: #428bca;
}

.callout-success {
  border-left-color: #5cb85c;
  background-color: #efffe8;
}

.callout-success .callout-title {
  color: #5cb85c;
}

.callout-danger {
  border-left-color: #d9534f;
  background-color: #fcf2f2;
}

.callout-danger .callout-title {
  color: #d9534f;
}

.callout-warning {
  border-left-color: #f0ad4e;
  background-color: #fefbed;
}

.callout-warning .callout-title {
  color: #f0ad4e;
}

.callout-info {
  border-left-color: #5bc0de;
  background-color: #f0f7fd;
}

.callout-info h4 {
  color: #5bc0de;
}

/* bg transparency and disabled effects for Bootstrap callout */
.callout-default.transparent {
  background-color: rgba(247, 247, 249, 0.7);
  /*#f7f7f9*/
}

.callout-success.transparent {
  background-color: rgba(239, 255, 232, 0.7);
  /*#efffe8*/
}

.callout-warning.transparent {
  background-color: rgba(254, 251, 237, 0.7);
  /*#fefbed*/
}

.callout-danger.transparent {
  background-color: rgba(252, 242, 242, 0.7);
  /*#fcf2f2*/
}

.callout-info.transparent {
  background-color: rgba(240, 247, 253, 0.7);
  /*#f0f7fd*/
}

.callout.disabled {
  opacity: 0.4;
}