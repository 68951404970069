#ulasim {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/baskan-img/backround viransehir.jpg");

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    border: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: hsl(0deg 0% 100% / 70%);
  }
  .ulasim {
    position: relative;
    padding: 40px 0 60px;
    h1 {
      font-weight: 900;
      display: flex;
      justify-content: center;
      color: #455f76;
      font-size: 1.4rem;
    }
    h1:nth-child(1) {
      font-size: 22px;
      color: #c86b53;
    }
  }
  .ulasim-satir {
    position: relative;
    z-index: 50;
    .adres-bilgisi {
      display: flex;
      flex-direction: row;
      .icon-arka-pilan {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -2px 20px 37px 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        .icon {
          font-size: 1.6rem;
        }
      }
      .info {
        h2 {
          color: #455f76;
          font-size: 1.3rem;
          font-weight: 600;
        }
        a {
          color: #040d15;
          font-weight: 600;
          font-size: 0.95rem;
          transition: all 0.5s ease 0s;
          &:hover {
            color: #1eb554;
          }
        }
      }
      .adres {
        color: #ea4d2a;
        background: rgba(233, 80, 35, 0.2);
      }
      .eposta {
        color: #22b356;
        background: rgba(34, 179, 86, 0.2);
      }
      .tel {
        color: #7f6bd8;
        background: rgba(127, 107, 216, 0.2);
      }
    }
    .bilgi-giris {
      margin-bottom: 40px;
      .info-input {
        border: 1px solid #cccfd1;
        background: none;
        border-radius: 10px;
        padding: 10px 0 10px 15px;
        font-size: 0.82rem;
        font-weight: 600;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          color: rgba(126, 124, 124, 0.74);
          font-size: 0.88rem;
        }
      }
      .btn {
        background: #1eb554;
        border: none;
        color: #ffffff;
        border-radius: 30px;
        height: 35px;
        padding: 6px 27px;
        font-size: 0.9rem;
        font-weight: 800;
        transition: all 0.5s;
        border: 1px solid #1eb554;
        &:hover {
          color: #1eb554;
          background: white;
          border: 1px solid #1eb554;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  #ulasim {
    .ulasim-satir {
      padding: 0 12px;
    }
  }
}
