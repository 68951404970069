#baskan-header {
  overflow: hidden;
  .baskan-header {
    position: relative;
    overflow: hidden;
    height: 100%;
    // height: 100vh;
    a {
      color: white;
      top: 20px;
      position: relative;
      background: #64a500;
      border-radius: 50px;
      display: inline;
      padding: 10px 25px;
      border: 1px solid #dde4eb;
      font-size: 0.9rem;
      font-weight: 800;
      transition: all 0.8s ease 0s;
      a {
        height: 40px;
        background: #64a500;
        border-radius: 50px;
        color: white;
        font-size: 16px;
        padding: 0 23px;
        white-space: pre-wrap;
        overflow: hidden;
        svg {
          font-size: 10px;
          margin-right: 5px;
        }
      }
      &:hover {
        background-color: rgb(26 67 110);
      }
    }
    .img-viran {
      position: relative;
      height: 400px;
      width: 100%;
      .image {
        object-fit: contain;
        width: 100% !important;
        position: relative !important;
        height: unset !important;
      }
    }
    .img-baskan {
      justify-content: center;
      position: relative;
      height: 100vh;
      width: 100%;
      .image {
        object-fit: cover;
        width: 100% !important;
        position: relative !important;
        height: unset !important;
      }
    }

    @media (min-width: 769px) and (max-width: 992px) {
      .img-viran {
        position: relative;
        height: 250px;
        width: 100%;
      }
      .img-baskan {
        justify-content: center;
        position: relative;
        height: 300px;
        width: 100%;
      }
    }

    @media (max-width: 992px) {
      padding-top: 100px;
      .img-baskan {
        height: 250px;
        .image {
          object-fit: fill;
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      .img-baskan {
        height: 500px;
        .image {
          object-fit: fill;
        }
      }
    }
  }
}
@media (max-width: 770px) {
  #baskan-header {
    .baskan-header {
      .btn {
        display: none;
      }
    }
  }
}

@media (min-width: 350px) and (max-width: 400px) {
  #baskan-header {
    .baskan-header {
      .img-viran {
        // margin-top: -50px;
      }
      .img-baskan {
        // margin-top: -30px;
      }
    }
  }
}
@media (min-width: 401px) and (max-width: 490px) {
  #baskan-header {
    .baskan-header {
      .img-viran {
        // margin-top: -30px;
        // padding: 10px;
      }
      .img-baskan {
        // margin-top: -20px;
      }
    }
  }
}
@media (min-width: 491px) and (max-width: 515px) {
  #baskan-header {
    .baskan-header {
      .img-viran {
        // margin-top: -40px;
        // padding: 20px;
      }
      .img-baskan {
        // margin-top: -40px;
        // padding: 20px;
      }
      .btn {
        display: none;
      }
    }
  }
}
@media (min-width: 516px) and (max-width: 575px) {
  #baskan-header {
    .baskan-header {
      .img-viran {
        // margin-top: -40px;
        // padding: 10px;
      }
      .img-baskan {
        // margin-top: -30px;
        // padding: 10px;
      }
      .btn {
        display: none;
      }
    }
  }
}
