#baskan-navbar {
  @media (min-width: 992px) and (max-width: 1200px) {
    .container {
      .btn a {
        padding: 10px 16px;
        font-size: 0.7rem;
      }
    }
  }

  &.home {
    min-height: auto;
  }

  .background {
    background-image: url(/baskan-img/ID1.png);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  .collapse {
    justify-content: flex-end;
  }

  .baskan-navbar {
    color: transparent;
    color: transparent;
    position: absolute;
    width: 100%;
    z-index: 2;
    .logo {
      margin-top: 10px;

      p {
        margin-top: -8px;
        padding: 0;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
      }
    }

    .navbar-collapse:not(.collapse:not(.show)) {
      .list {
        background: #ffffff;
        padding: 10px;
        left: 0;
        width: 100%;
      }

      .navbar-toggler &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: red !important;
      }
    }

    .list {
      margin-top: -25px;
      position: absolute;
      font-weight: 600;
      align-items: center;
      // font-family: "jost" !important;
      line-height: 30px;
      z-index: 50;
      transition: 0.5s ease;

      a {
        transition: all 0.5s;
        span {
          padding: 0 5px;
          font-size: 1.12rem;
          margin-right: 8px;
          color: #ffffff;
          cursor: pointer;
          position: relative;
          transition: all 0.5s;

          &::after {
            content: "";
            position: absolute;
            display: block;
            width: 0%;
            height: 3px;
            background: #e10614;
            text-align: center;
            transition: all 0.5s;
            border-radius: 25px;
          }

          &:hover:after {
            width: 80%;
          }

          &:hover {
            color: #e10614;
          }
        }
      }
    }

    #button-click {
      background-color: white;
      position: relative;
      top: -11px;
    }

    .icon-list:not(.detay .icon-list) {
      list-style-type: none;
      margin-top: 15px !important;
      display: flex;
      justify-content: center;
      padding: 7;

      .facebook {
        a {
          background-color: #3b5998;
          color: #fff;
        }
      }

      .twitter {
        a {
          background-color: #1da1f2;
          color: #fff;
        }
      }

      .instegram {
        a {
          background: rgb(170, 51, 176);
          color: #fff;
        }
      }

      li {
        display: inline;
        margin: 0 3px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        a.icon-list-background {
          display: flex;
          font-size: 15px;
          padding: 8px;
          border: 1px solid #b3b3b3;
          border-radius: 8px;
          color: #ffffff;

          &:hover {
            color: white;
          }
        }
      }
    }
  }

  .btn {
    a {
      background: #64a500;
      border-radius: 50px;
      display: inline;
      padding: 10px 25px;
      border: 1px solid #dde4eb;
      font-size: 0.9rem;
      font-weight: 800;
      transition: all 0.8s ease 0s;
      color: white;

      a {
        background: #64a500;
        border-radius: 50px;
        color: white;
        font-size: 16px;
        padding: 0 23px;
        white-space: pre-wrap;
        overflow: hidden;

        svg {
          font-size: 10px;
          margin-right: 5px;
        }
      }

      &:hover {
        background-color: rgb(26 67 110);
      }
    }
  }
}

.detay {
  .baskan-navbar {
    .navbar {
      height: 97px;
      align-items: baseline;
    }
    position: initial !important;
    .logo {
      p {
        color: #1d1b1b !important;
      }
    }
    .list {
      a {
        span {
          color: #211e1e !important;
        }
      }
    }
    .icon-list {
      list-style-type: none;
      margin-top: 15px !important;
      display: flex;
      justify-content: center;
      padding: 7;

      .facebook {
        &:hover {
          a {
            background-color: #3b5998;
            color: #fff;
          }
        }
      }
      .twitter {
        &:hover {
          a {
            background-color: #1da1f2;
            color: #fff;
          }
        }
      }
      .instegram {
        &:hover {
          a {
            background: rgb(170, 51, 176);
            color: #fff;
          }
        }
      }

      li {
        display: inline;
        margin: 0 3px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        a.icon-list-background {
          display: flex;
          font-size: 15px;
          padding: 8px;
          border: 1px solid #b3b3b3;
          border-radius: 8px;
          color: #1d1b1b;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .background {
    bottom: 20px !important;
  }

  .home {
    min-height: auto !important;
  }

  #baskan-navbar {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .logo {
    p {
      color: #242222 !important;
    }
  }
}

@media (max-width: 992px) {
  .list {
    a {
      span {
        color: #0b0b0b !important;
      }
    }
  }
}
